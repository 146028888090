@import "./colors";

.flex-1{
  flex: 1;
}
.centerText {
  text-align: center;
}

.list-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
}

body {
  font-size: 16px;
  line-height: 2rem;
  // letter-spacing: 0.08em;
  font-family: Roboto, sans-serif !important;
  font-weight: 300;
  color: $darkText;
}

h1,
h2 {
  color: $darkText;

  &.light {
    color: $lightText;
  }
}
h3,
h4,
h5,
h6 {
  color: $tetra1;

  &.light {
    color: $lightText;
  }
}
p {
  white-space: pre-wrap;
}

img {
  max-width: 100%;
  max-height: 20em;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

div#app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.card.showcase {
  margin: 4px;
  margin-bottom: 0.75rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.card1 {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  .card1-body {
    padding: 15px 30px;
    color: $lightText;
  }

  .card1-title {
    color: $lightText;
    text-decoration: none;
  }

  .card1-text {
    p {
      font-size: small;
    }
  }

  .card1-footer {
    margin-top: 15px;
  }
}

.fill-dark {
  background-color: $tetra1 !important;
  transition: background-color 1s;

  small {
    color: lightsteelblue;
  }
}

.fill-dark-acc {
  background-color: $tetra2 !important;
  transition: background-color 1s;
}

.fill-light-acc {
  background-color: $lightAccent !important;
  transition: background-color 1s;

  small {
    color: lightblue;
  }
}

.fill-gray-disabled {
  background-color: #e9ecef !important;
  transition: background-color 1s;
}
.hub31-nav {
  background-color: $positive;
  border: 0;
  border-radius: 3px;
  padding: 10px 0;

  .navbar-brand {
    color: $lightText;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: x-large;
  }

  .navbar-brand > img {
    margin: 7px 14px;
    border-radius: 5px;
    border: 2px solid $lightText;
  }

  .navbar-nav .nav-link {
    color: $lightText;
    font-weight: bold;
    padding: 10px 15px;
  }

  .navbar-nav > .active > a,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    color: $lightText;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  .navbar-toggler,
  .navbar-toggler:hover {
    border-color: $positive;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 15px;
  }

  .navbar-collapse {
    border-color: $positive;
  }

  .dropdown-menu {
    padding: 0.5rem;
    z-index: 1300;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:focus,
  .dropdown-menu > .active > a:hover {
    background-color: $positive;
  }
}

.borderless td,
.borderless th {
  border: none !important;
}

.col-box {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 20px 0 20px 0;
  padding: 10px;

  ul {
    min-height: 150px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }
}

.renderTrue {
  background: lighten(green, 60%) !important;
  border-color: green !important;
}

.renderFalse {
  background: lighten(red, 40%) !important;
  border-color: red !important;
}

.hml-10 {
  margin-left: 10px;
}

.hmr-10 {
  margin-right: 10px;
}

.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

.course-card-grad {
  min-height: 5rem;
  /* background-color: orange; */
  background-image: linear-gradient(#ff9d2f, #ff6126);
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 80%;

  p {
    color: white;
    text-shadow: 1px 1px 20px black;
    font-size: 1.25rem;
  }
}

.renderQuestion {
  margin: 10px;
}

.justifyText {
  text-align: justify;
}

.hub31-footer {
  background-color: $footer;
  font-size: 0.8rem;

  h6 {
    color: $balticSea;
    font-size: 0.8rem;
  }
}
.Loading {
  flex: 1 1 auto !important;
}
.star-gold {
  color: #ffb400
}
.cursor-pointer{
  cursor: pointer;
}
.truncated-text {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.line-height-1{
  line-height: 1rem;
}

.min-vh-50 {
  min-height: 50vh;
}

.border-width-2 {
  border-width: 0.5rem !important;
}
