form label.error {
  display: block;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: normal;
  color: lighten(red, 20%);
}

#custom-file {
  cursor: pointer;
}
