// http://colormind.io/bootstrap/
$deepOrange: #ff8100;
$lightOrange: #ffa700;
$lightblue: #91bfff;
$midblue: #5292ff;
$highblue: #4c6aff;

$athensgray: #f9f9fa;
$nepal: #8bb3bc;
$tango: #ff8100;
$flushMahogany: #cd4440;
$balticSea: #25232e;

$positive: $deepOrange;
$lightText: $athensgray;
$lightAccent: #0054AA;
$darkAccent: $flushMahogany;
$darkText: $balticSea;
$tetra1: #8000ff;
$tetra2: #7da140;
$footer: whitesmoke;

$grey-0: #808080;
$grey-1: #cdd8ec;
$grey-2: #f4f7f9;
$grey-3: #b5b5b5;
$white: #fff;
